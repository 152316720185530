import { ReducersMapObject } from 'redux';

import * as Items from '@common/react/store/ItemList';
import { BaseApplicationState, BaseAppThunkAction, baseReducers } from '@common/react/store';
import { PageItemState, reducer as PageStateReducer } from '@common/react/store/PageItem';

import { User } from '@app/objects/User';
import { Event } from '@app/objects/Event';

// The top-level state object
export interface ApplicationState extends BaseApplicationState<User> {
	serverPage: PageItemState<any>;
	upcomingEvents: Items.ItemsState<Event>;
	prevEvents: Items.ItemsState<Event>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers: ReducersMapObject = {
	...baseReducers,

	serverPage: PageStateReducer<any>('serverPage'),
	upcomingEvents: Items.getReducer<Event>('upcomingEvents'),
	prevEvents: Items.getReducer<Event>('prevEvents'),
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export type AppThunkAction<TAction> = BaseAppThunkAction<TAction, User, ApplicationState>;
