import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import { ApplicationState } from '@app/store';
import eventDate from '@app/utils/eventDate';
import { Event } from '@app/objects/Event';
import Media from '@app/components/UI/Media/Media';
import { menuLeft, menuRight } from '@app/utils/routesVerification';

const TopLine: React.FC = () => {
	const events: Array<Event> = useSelector((state: ApplicationState) => state.upcomingEvents.items);
	const [activeSlide, setActiveSlide] = React.useState<number>(0);

	const slides = events.map((event, index) => {
		const dateFrom = event.dateFrom !== null && new Date(event.dateFrom);
		const dateTo = event.dateTo !== null && new Date(event.dateTo);
		return (
			<span key={index} className="block-event">
				<span className="text-primary">Upcoming events:</span>
				<span className="text-secondary">
					<span className="yellow-text">
						{event.url
							? <a href={event.url} target="_blank" rel="nofollow noreferrer">{event.name}</a>
							: `${event.name}`
						}
					</span>
					&nbsp;
					{eventDate(dateFrom, dateTo)}
					, &nbsp;
					{event.place}
				</span>
			</span>
		);
	});

	React.useEffect(() => {
		if (activeSlide < slides.length) {
			setTimeout(() => {
				setActiveSlide(activeSlide + 1);
			}, 10000);
		} else {
			setActiveSlide(0);
		}
	}, [activeSlide]);

	return (
		<div className="site-header__top">
			<div className="container">
				<div className="block-info">
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
						<path
							d="M19.2008 8V11.2M12.8008 8V11.2M8.80078 14.4H23.2008M10.4008 9.6H21.6008C22.4844 9.6 23.2008
							10.3163 23.2008 11.2V22.4C23.2008 23.2837 22.4844 24 21.6008 24H10.4008C9.51713 24 8.80078 23.2837
							8.80078 22.4V11.2C8.80078 10.3163 9.51713 9.6 10.4008 9.6Z"
							stroke="white"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					{slides[activeSlide]}
				</div>
				<div className="header-media">
					<Media />
				</div>
			</div>
		</div>
	);
};

const Header: React.FC = () => {
	const login = useSelector((state: ApplicationState) => state.login, shallowEqual);
	const [open, setOpen] = React.useState(false);
	const location = useLocation();

	React.useEffect(() => {
		setOpen(false);
	}, [location.pathname]);

	return <header className="site-header">
		<div className="site-header-fixed-top">
			<TopLine />
			<div className={`site-header__bottom ${open ? 'site-header__bottom__open' : ''}`}>
				<div className="container">
					<picture>
						<source media="(max-width: 1100px)" srcSet="/images/header-bg-m.png" />
						<source media="(min-width: 1101px)" srcSet="/images/header-bg.svg" />
						<img className="header-bg" srcSet="/images/header-bg.svg" alt="bg" tabIndex={-1} />
					</picture>
					<ul className="menu-left is-relative">
						{menuLeft.map((item) => (
							<li key={item.link}>
								<NavLink to={item.link}>
									{item.name}
								</NavLink>
							</li>
						))}
					</ul>
					<div className="header-media">
						<Media />
					</div>
					<div className="site-header__logo">
						<NavLink to="/">
							<img
								width="241px"
								height="139px"
								alt="logo"
								src="/images/logo.svg"
							/>
						</NavLink>
					</div>
					<ul className="menu-right">
						{menuRight.map((item) => (
							<li key={item.link}>
								<NavLink to={item.link}>
									{item.name}
								</NavLink>
							</li>
						))}
						{login.user && (
							<NavLink
								to="/login"
								className="link-admin"
							>
								Admin
							</NavLink>
						)}
					</ul>
				</div>
			</div>
			<button
				type="button"
				onClick={() => setOpen((open) => !open)}
				className="toggle-menu"
			>
				<img
					alt="toggle-menu"
					width={43}
					height={43}
					src={open ? '/icons/close.svg' : '/icons/menu.svg'}
				/>
			</button>
		</div>
	</header>;
};

export default Header;
