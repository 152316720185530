import * as React from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { menuLeftLanding, menuRightLanding } from '@app/utils/routesVerification';
import Media from '@app/components/UI/Media/Media';

const HeaderLanding: React.FC = () => {
	const [open, setOpen] = React.useState(false);
	const location = useLocation();
	const history: any = useHistory();

	React.useEffect(() => {
		setOpen(false);
	}, [location.hash]);

	React.useEffect(() => {
		if (document && history.location.state) {
			const ref = document.getElementById(history.location.state);

			if (ref) {
				ref.scrollIntoView();
				history.push('/children', '');
			}
		}
	}, [history.location.state]);

	return <header className="site-header">
		<div className="site-header-fixed-top">
			<div className={`site-header__bottom ${open ? 'site-header__bottom__open' : ''}`}>
				<div className="container">
					<picture>
						<source media="(max-width: 1100px)" srcSet="/images/header-bg-m.png" />
						<source media="(min-width: 1101px)" srcSet="/images/header-bg.svg" />
						<img className="header-bg" srcSet="/images/header-bg.svg" alt="bg" tabIndex={-1} />
					</picture>
					<ul className="menu-left is-relative">
						{menuLeftLanding.map((item) => (
							<li key={item.link}>
								<a
									href={`#${item.link}`}
									className="text-secondary"
									onClick={(e) => {
										e.preventDefault();
										history.push('/children', item.link);
									}}
								>
									{item.name}
								</a>
							</li>
						))}
					</ul>
					<div className="site-header__logo">
						<NavLink to="/">
							<img
								width="241px"
								height="139px"
								alt="logo"
								src="/images/logo.svg"
							/>
						</NavLink>
					</div>
					<ul className="menu-right">
						{menuRightLanding.map((item) => (
							<li key={item.link}>
								<a
									href={`#${item.link}`}
									className="text-secondary"
									onClick={(e) => {
										e.preventDefault();
										history.push('/children', item.link);
									}}
								>
									{item.name}
								</a>
							</li>
						))}
						<div className="header-landing-media">
							<Media />
						</div>
					</ul>
				</div>
			</div>
			<button
				type="button"
				onClick={() => setOpen((open) => !open)}
				className="toggle-menu"
			>
				<img
					alt="toggle-menu"
					width={43}
					height={43}
					src={open ? '/icons/close.svg' : '/icons/menu.svg'}
				/>
			</button>
		</div>
	</header>;
};

export default HeaderLanding;
