import React from 'react';
import { useLocation } from 'react-router-dom';

const ScrollTop: React.FC = (props) => {
	const { pathname } = useLocation();

	const [prevPath, setPrevPath] = React.useState<string>('');

	React.useEffect(() => {
		if (prevPath) {
			if (!pathname.includes(prevPath)) {
				window.scrollTo(0, 0);
				setPrevPath(pathname.slice(1).split('/')[0]);
			}
		} else {
			window.scrollTo(0, 0);
			setPrevPath(pathname.slice(1).split('/')[0]);
		}
	}, [pathname]);

	return <>
		{props.children}
	</>;
};

export default ScrollTop;
