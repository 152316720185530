import { ReducersMapObject } from 'redux';

import * as Login from '@common/react/store/Login';
import * as Item from '@common/react/store/Item';
import { BaseUser } from '@common/react/objects/BaseUser';
import { BuildData } from '@common/react/objects/BuildData';
import BaseHostOptions from '@common/react/store/BaseHostOptions';

// The top-level state object
export interface BaseApplicationState<TUser extends BaseUser> {
	login: Login.LoginState<TUser>;
	buildData: Item.ItemState<BuildData>;
	hostOptions: Item.ItemState<BaseHostOptions>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const baseReducers: ReducersMapObject = {
	login: Login.getReducer(),
	buildData: Item.getReducer<BuildData>('buildData'),
	hostOptions: Item.getReducer<BaseHostOptions>('hostOptions'),
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface BaseAppThunkAction<TAction, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	(dispatch: (action: TAction) => void, getState: () => TApplicationState): void;
}
