import React from 'react';

import HeaderLanding from '@app/components/UI/Header/HeaderLanding';
import FooterLanding from '@app/components/UI/Footer/FooterLanding';

const LayoutLanding: React.FC = ({ children }) => {
	return (
		<>
			<HeaderLanding />
			<div className="content">{children}</div>
			<FooterLanding />
		</>
	);
};

export default LayoutLanding;
