import * as React from 'react';

import { RequestProvider } from '@common/react/components/RequestProvider/RequestProvider';

import ErrorBoundary from '@app/components/UI/ErrorBoundary/ErrorBoundary';
import '@app/scss/style.scss';

const Layout: React.FC = ({ children }) => {
	return (
		<ErrorBoundary>
			<RequestProvider>
				<div className="app-container">{children}</div>
			</RequestProvider>
		</ErrorBoundary>
	);
};

export default Layout;
