import React from 'react';

import Header from '@app/components/UI/Header/Header';
import Footer from '@app/components/UI/Footer/Footer';

const LayoutMain: React.FC = ({ children }) => {
	return (
		<>
			<Header />
			<div className="content">{children}</div>
			<Footer />
		</>
	);
};

export default LayoutMain;
