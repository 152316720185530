import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { UnregisterCallback } from 'history';

import '@app/scss/components/errorBoundary.scss';

export class ErrorBoundary extends React.Component<RouteComponentProps<object>, { hasError: boolean }> {
	unlisten: UnregisterCallback | null = null;

	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentWillUnmount() {
		this.unlisten && this.unlisten();
	}

	componentDidMount() {
		this.unlisten = this.props.history.listen((location, action) => {
			if (this.state.hasError) {
				this.setState({ hasError: false });
			}
		});
	}

	componentDidCatch(error, errorInfo) {
		this.setState({ hasError: true });
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className="error-handling">
					<h1>Whoops, something went wrong</h1>
					<p>Please either refresh the page or return home to try again.</p>
					<p>If the issue continues, please get in touch.</p>
					<a href="#" className="btn btn-primary">
						Go home
					</a>
				</div>
			);
		}

		return this.props.children;
	}
}

export default withRouter(ErrorBoundary);
