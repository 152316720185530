import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import FooterAdmin from '@app/components/UI/Footer/FooterAdmin';
import HeaderAdmin from '@app/components/UI/Header/HeaderAdmin';

import { ApplicationState } from '@app/store';

const LayoutAdmin: React.FC = ({ children }) => {
	const login = useSelector((state: ApplicationState) => state.login, shallowEqual);

	return (
		login.user
			? <>
				<HeaderAdmin />
				<div className="content">
					{children}
				</div>
				<FooterAdmin />
			</>
			: <Redirect to="/login" />
	);
};

export default LayoutAdmin;
