import * as React from 'react';
import { NavLink } from 'react-router-dom';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import { links } from '@app/utils/routesVerification';

const year = new Date().getFullYear();

const Footer: React.FC = () => {
	const copyRight = `© ${year} All rights reserved.`;

	return <footer className="site-footer">
		<div className="container clearfix">
			<div className="logo-container pull-left">
				<NavLink to="/">
					<ImageLazy
						alt="logo"
						width={256}
						height={73}
						src="/images/footer-logo.svg"
					/>
				</NavLink>
				<div className="site-footer__copyright mobile-hidden">
					{copyRight}
				</div>
			</div>
			<div className="footer-links">
				{links.map(((item) => <NavLink to={item.link} key={item.name}>{item.name}</NavLink>))}
			</div>
			<div className="site-footer__copyright show-mobile">
				{copyRight}
			</div>
		</div>
	</footer>;
};

export default Footer;
