export const menuLeft = [
	{
		link: '/about',
		name: 'About',
	},
	{
		link: '/geriatric-pets',
		name: 'Geriatric Pets',
	},
	{
		link: '/speaking',
		name: 'Speaking',
	},
];

export const menuRight = [
	{
		link: '/books',
		name: 'Books',
	},
	{
		link: '/pet-parents',
		name: 'Pet Parents',
	},
	{
		link: '/education',
		name: 'Education',
	},
	{
		link: '/contact',
		name: 'Contact',
	},
];

export const menuLeftAdmin = [
	{
		link: '/admin/inquiries',
		name: 'Inquiries ',
	},
	{
		link: '/admin/events',
		name: 'Events',
	},
];

export const menuRightAdmin = [
	{
		link: '/admin/books',
		name: 'Books',
	},
	{
		link: '/admin/blog',
		name: 'Blog',
	},
];

export const menuLeftLanding = [
	{
		link: 'children-books',
		name: 'Children’s Books',
	},
	{
		link: 'about',
		name: 'About',
	},
	{
		link: 'educational-books',
		name: 'Educational Books',
	},
];

export const menuRightLanding = [
	{
		link: 'free-goodies',
		name: 'Free Goodies',
	},
	{
		link: 'contact',
		name: 'Contact',
	},
];

export const links = [
	{
		link: '/about',
		name: 'About',
	},
	{
		link: '/geriatric-pets',
		name: 'Geriatric Pets',
	},
	{
		link: '/speaking',
		name: 'Speaking',
	},
	{
		link: '/books',
		name: 'Books',
	},
	{
		link: '/education',
		name: 'Education',
	},
	{
		link: '/contact',
		name: 'Contact',
	},
];

export const linksAdmin = [
	{
		link: '/admin/inquiries',
		name: 'Inquiries ',
	},
	{
		link: '/admin/events',
		name: 'Events',
	},
	{
		link: '/admin/books',
		name: 'Books',
	},
	{
		link: '/admin/blog',
		name: 'Blog ',
	},
];

export const linksLanding = [
	{
		navLink: false,
		link: 'children-books',
		name: 'Children’s Books',
	},
	{
		navLink: false,
		link: 'about',
		name: 'About',
	},
	{
		navLink: false,
		link: 'educational-books',
		name: 'Educational Books',
	},
	{
		navLink: false,
		link: 'free-goodies',
		name: 'Free Goodies',
	},
	{
		navLink: false,
		link: 'contact',
		name: 'Contact',
	},
];
