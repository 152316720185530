import * as React from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import { linksLanding } from '@app/utils/routesVerification';

const year = new Date().getFullYear();

const FooterLanding: React.FC = () => {
	const location = useLocation();
	const history = useHistory();

	const copyRight = `© ${year} All rights reserved.`;

	React.useEffect(() => {
		const hashName = location.hash;

		if (hashName) {
			window.location.hash = '';
			window.location.hash = hashName;
		}
	}, [location.hash]);

	return <footer className="site-footer">
		<div className="container clearfix">
			<div className="logo-container pull-left">
				<NavLink to="/">
					<ImageLazy
						alt="logo"
						width={256}
						height={73}
						src="/images/footer-logo.svg"
					/>
				</NavLink>
				<div className="site-footer__copyright mobile-hidden">
					{copyRight}
				</div>
			</div>
			<div className="footer-links">
				{linksLanding.map((item) => (
					<a
						href={`#${item.link}`}
						key={item.name}
						onClick={(e) => {
							e.preventDefault();
							history.push(`#${item.link}`);
						}}
					>
						{item.name}
					</a>
				))}
			</div>
			<div className="site-footer__copyright show-mobile">
				{copyRight}
			</div>
		</div>
	</footer>;
};

export default FooterLanding;
