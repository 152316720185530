const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const eventDate = (dateFrom, dateTo): string => {
	if (dateFrom && dateTo) {
		if (dateTo.getUTCFullYear() === dateFrom.getUTCFullYear()) {
			if (dateTo.getUTCMonth() === dateFrom.getUTCMonth()) {
				if (dateTo.getUTCDate() === dateFrom.getUTCDate()) {
					return `${dateTo.toLocaleDateString('en-US', {
						timeZone: 'UTC',
						month: 'long',
						year: 'numeric',
						day: 'numeric',
					})}`;
				}
				return `${months[dateTo.getUTCMonth()]} ${dateFrom.getUTCDate()}-${dateTo.getUTCDate()}, ${dateTo.getUTCFullYear()}`;
			}
			return `${months[dateFrom.getUTCMonth()]} ${dateFrom.getUTCDate()} - ${months[dateTo.getUTCMonth()]} ${dateTo.getUTCDate()}, 
				${dateTo.getUTCFullYear()}`;
		}
		return `${months[dateFrom.getUTCMonth()]} ${dateFrom.getUTCDate()}, ${dateFrom.getUTCFullYear()} - 
			${months[dateTo.getUTCMonth()]} ${dateTo.getUTCDate()}, ${dateTo.getUTCFullYear()}`;
	}
	if (!dateTo && dateFrom) {
		return `${dateFrom.toLocaleDateString('en-US', {
			timeZone: 'UTC',
			month: 'long',
			year: 'numeric',
			day: 'numeric',
		})}`;
	}
	if (dateTo && !dateFrom) {
		return `${dateTo.toLocaleDateString('en-US', {
			timeZone: 'UTC',
			month: 'long',
			year: 'numeric',
			day: 'numeric',
		})}`;
	}
	return '';
};

export default eventDate;
