import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import loadable from '@loadable/component';

import { loadableDelay, params } from '@common/react/loadable/loadableSettings';

import ScrollTopAll from '@app/hooks/ScrollTopAll';
import ScrollTopFirstRender from '@app/hooks/ScrollTopFirstRender';
import Layout from '@app/components/Layout';
import LayoutMain from '@app/components/LayoutMain';
import LayoutLanding from '@app/components/LayoutLanding';
import LayoutAdmin from '@app/components/LayoutAdmin';

const Home = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "HomePage" */ '@app/components/Pages/Home/Home')), params);
const About = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AboutPage" */ '@app/components/Pages/About/About')), params);
const GeriatricPets = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "GeriatricPets" */ '@app/components/Pages/GeriatricPets/GeriatricPets')), params);

const Speaking = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Speaking" */ '@app/components/Pages/Speaking/Speaking')), params);
const Books = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Books" */ '@app/components/Pages/Books/Books')), params);
const BookPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BookPage" */ '@app/components/Pages/BookPage/BookPage')), params);
const Education = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Education" */ '@app/components/Pages/Education/Education')), params);
const EducationPost = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "EducationPost" */ '@app/components/Pages/EducationPost/EducationPost')), params);
const ArticlePage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ArticlePage" */ '@app/components/Pages/Article/Article')), params);
const PetParents = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PetParents" */ '@app/components/Pages/PetParents/PetParents')), params);
const Contacts = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Contacts" */ '@app/components/Pages/Contacts/Contacts')), params);
const Children = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Contacts" */ '@app/components/Pages/Children/Children')), params);
const Login = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Login" */ '@app/components/Pages/Login/Login')), params);
const Admin = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Admin" */ '@app/components/Pages/Admin/Admin')), params);
const AdminBooks = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AdminBooks" */ '@app/components/Pages/Admin/AdminBooks')), params);
const AdminBookEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AdminBookEditor" */ '@app/components/Pages/Admin/AdminBookEditor')), params);
const AdminEvents = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AdminEvents" */ '@app/components/Pages/Admin/AdminEvents')), params);
const AdminEventEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AdminEventEditor" */ '@app/components/Pages/Admin/AdminEventEditor')), params);
const AdminInquiries = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AdminInquiries" */ '@app/components/Pages/Admin/AdminInquiries')), params);
const AdminBlog = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AdminBlog" */ '@app/components/Pages/Admin/AdminBlog')), params);
const AdminArticlesEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AdminArticlesEditor" */ '@app/components/Pages/Admin/AdminArticlesEditor')), params);
const Memorials = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "MemorialsPage" */ '@app/components/Pages/Resources/Memorials')), params);
const References = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ReferencesPage" */ '@app/components/Pages/Resources/References')), params);

export const routes = (
	<Layout>
		<Switch>
			<Route exact path={['/books/:urlPath?', '/education/:category?']}>
				<ScrollTopFirstRender>
					<LayoutMain>
						<Route exact path="/books/:urlPath?" component={Books} />
						<Route exact path="/education/:category?" component={Education} />
					</LayoutMain>
				</ScrollTopFirstRender>
			</Route>
			<Route exact path="/children">
				<ScrollTopAll>
					<LayoutLanding>
						<Route exact path="/children" component={Children} />
					</LayoutLanding>
				</ScrollTopAll>
			</Route>
			<Route
				exact
				path={[
					'/admin',
					'/admin/*',
				]}
			>
				<ScrollTopAll>
					<LayoutAdmin>
						<Route path="/admin/books" component={AdminBooks} />
						<Route path="/admin/book/:id" component={AdminBookEditor} />
						<Route path="/admin/events" component={AdminEvents} />
						<Route path="/admin/event/:id" component={AdminEventEditor} />
						<Route path="/admin/inquiries/:tab?" component={AdminInquiries} />
						<Route path="/admin/blog" component={AdminBlog} />
						<Route path="/admin/article/:id" component={AdminArticlesEditor} />
					</LayoutAdmin>
				</ScrollTopAll>
			</Route>
			<Route>
				<ScrollTopAll>
					<LayoutMain>
						<Route exact path="/" component={Home} />
						<Route path="/about" component={About} />
						<Route path="/geriatric-pets" component={GeriatricPets} />
						<Route path="/speaking" component={Speaking} />
						<Route path="/books/:urlPath/:path" component={BookPage} />
						<Route path="/education/:category/:post" component={EducationPost} />
						<Route path="/pet-parents" component={PetParents} />
						<Route path="/contact" component={Contacts} />
						<Route path="/login" component={Login} />
						<Route path="/resources/dogreferences" component={References} />
						<Route path="/resources/memorials" component={Memorials} />
					</LayoutMain>
				</ScrollTopAll>
			</Route>
		</Switch>
	</Layout>
);
