import React from 'react';

import { useLoaderProviderContext } from '@common/react/components/Core/LoadingProvider/LoadingProvider';

interface Props {
	defaultLoader?: JSX.Element;
}

const Loader: React.FC<Props> = ({ defaultLoader }) => {
	const loaderContext = useLoaderProviderContext();

	return loaderContext?.state?.pageLoader || defaultLoader || '';
};

export default Loader;
