import React from 'react';

export const media = [
	{
		icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path
				d="M20.6292 7.87771C20.4217 7.14346 19.8137 6.56467 19.0427 6.36696C17.6341 6 11.9998 6 11.9998 6C11.9998
				6 6.36567 6 4.95711 6.35306C4.20091 6.55056 3.57811 7.1436 3.37069 7.87771C3 9.21878 3 12 3 12C3 12 3
				14.7953 3.37069 16.1223C3.57831 16.8564 4.18609 17.4352 4.95722 17.6329C6.38049 18 12 18 12 18C12 18
				17.6341 18 19.0427 17.6469C19.8138 17.4493 20.4217 16.8705 20.6293 16.1364C20.9999 14.7952 20.9999
				12.0141 20.9999 12.0141C20.9999 12.0141 21.0147 9.21875 20.6292 7.87771ZM10.2059 14.5694V9.43058L14.8912 12L10.2059 14.5694Z"
				fill="white"
			/>
		</svg>,
		link: 'https://www.youtube.com/channel/UCp9kmv63ZbC8t_movGOPj4Q',
	},
	{
		icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path
				d="M11.9984 9C10.3439 9 9 10.3439 9 11.9984C9 13.6529 10.3439 15 11.9984 15C13.6529 15 15 13.6529 15
				11.9984C15 10.3439 13.6529 9 11.9984 9Z"
				fill="white"
			/>
			<path
				d="M16.4288 4H7.5712C5.6032 4 4 5.6032 4 7.5712V16.4288C4 18.4 5.6032 20 7.5712 20H16.4288C18.4 20 20
				18.4 20 16.4288V7.5712C20 5.6032 18.4 4 16.4288 4ZM12 17.184C9.1424 17.184 6.816 14.8576 6.816 12C6.816
				9.1424 9.1424 6.8192 12 6.8192C14.8576 6.8192 17.184 9.1424 17.184 12C17.184 14.8576 14.8576 17.184 12
				17.184ZM17.2928 7.76C16.688 7.76 16.1952 7.2704 16.1952 6.6656C16.1952 6.0608 16.688 5.568 17.2928
				5.568C17.8976 5.568 18.3904 6.0608 18.3904 6.6656C18.3904 7.2704 17.8976 7.76 17.2928 7.76Z"
				fill="white"
			/>
		</svg>,
		link: 'https://www.instagram.com/drmarygardner/',
	},
	{
		icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path
				d="M12.8423 21V12.79H15.4947L15.8926 9.58941H12.8423V7.54632C12.8423 6.61998 13.0889 5.98869 14.3695
				5.98869L16 5.98799V3.12531C15.718 3.08725 14.7501 3 13.6235 3C11.271 3 9.66048 4.49127 9.66048
				7.22934V9.58941H7V12.79H9.66048V21H12.8423Z"
				fill="white"
			/>
		</svg>,
		link: 'https://www.facebook.com/drmarygardner/',
	},
	{
		icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path
				d="M17.1843 4.84453C15.9582 3.6551 14.2614 3 12.4065 3C9.57306 3 7.83036 4.17371 6.86737 5.15827C5.68056
				6.37162 5 7.98272 5 9.57854C5 11.5822 5.82936 13.1201 7.21822 13.6922C7.31146 13.7308 7.40528 13.7503
				7.49726 13.7503C7.79026 13.7503 8.02241 13.5566 8.10284 13.2458C8.14972 13.0675 8.25836 12.6277 8.30559
				12.4367C8.4067 12.0597 8.32501 11.8783 8.10451 11.6157C7.70282 11.1354 7.51576 10.5675 7.51576
				9.8283C7.51576 7.63276 9.13356 5.29934 12.132 5.29934C14.5112 5.29934 15.9891 6.66579 15.9891
				8.86539C15.9891 10.2534 15.6932 11.5389 15.1558 12.4851C14.7824 13.1426 14.1257 13.9264 13.1176
				13.9264C12.6817 13.9264 12.2901 13.7454 12.043 13.4299C11.8096 13.1317 11.7327 12.7464 11.8266
				12.3449C11.9326 11.8913 12.0772 11.4181 12.2172 10.9606C12.4724 10.1251 12.7137 9.33593 12.7137
				8.70632C12.7137 7.62939 12.0585 6.90579 11.0835 6.90579C9.84446 6.90579 8.87372 8.17755 8.87372
				9.80107C8.87372 10.5973 9.08312 11.1928 9.17792 11.4215C9.02182 12.0898 8.09411 16.0632 7.91814
				16.8125C7.8164 17.2499 7.20346 20.7044 8.21798 20.9799C9.35785 21.2894 10.3767 17.9249 10.4804
				17.5447C10.5645 17.2355 10.8586 16.0663 11.0388 15.3476C11.5891 15.8832 12.4751 16.2453 13.3372
				16.2453C14.9624 16.2453 16.424 15.5062 17.4527 14.1644C18.4505 12.8629 19 11.0489 19 9.0568C19
				7.49941 18.3382 5.96407 17.1843 4.84453Z"
				fill="white"
			/>
		</svg>,
		link: 'https://www.pinterest.com/greymuzzlevet',
	},
	{
		icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path
				d="M19.996 20L20 19.9993V14.1313C20 11.2607 19.382 9.04933 16.026 9.04933C14.4127 9.04933 13.33 9.93467
				12.888 10.774H12.8413V9.31733H9.65933V19.9993H12.9727V14.71C12.9727 13.3173 13.2367 11.9707 14.9613
				11.9707C16.6607 11.9707 16.686 13.56 16.686 14.7993V20H19.996ZM4.264 9.318H7.58133V20H4.264V9.318ZM5.92133
				4C4.86067 4 4 4.86067 4 5.92133C4 6.982 4.86067 7.86067 5.92133 7.86067C6.982 7.86067 7.84267 6.982 7.84267
				5.92133C7.84231 5.41187 7.63977 4.92338 7.27953 4.56314C6.91929 4.20289 6.43079 4.00035 5.92133 4Z"
				fill="white"
			/>
		</svg>,
		link: 'https://www.linkedin.com/in/mary-gardner-dvm-5714152b/',
	},
];

const Media = () => {
	return (
		<>
			{media.map((item, index) => (
				<a
					key={`${index}${item.link}`}
					href={item.link}
					target="_blank"
					rel="nofollow noreferrer"
				>
					{item.icon}
				</a>
			))}
		</>
	);
};

export default Media;
